












































import Vue from "vue";
import Event from "@/components/Event.vue";
import { orderBy } from "lodash";
export default Vue.extend({
  components: {
    Event,
  },
  data() {
    return {
      interval: null as any,
    };
  },
  computed: {
    user(): any {
      return this.$store.state.user;
    },
    users(): any {
      return this.$store.state.users;
    },
    events() {
      return this.$store.state.events;
    },
    points(): number {
      let points = 0;
      for (const event of this.events) {
        if (
          event.users.findIndex(
            (user: any) =>
              user.id === this.$store.state.user.id &&
              user.checked_in_at !== null &&
              user.accepted_at !== null
          ) >= 0
        ) {
          points++;
        }
      }
      return points;
    },
    ranking(): any[] {
      const ranking: any[] = [];
      for (const user of this.users) {
        let points = 0;
        for (const event of this.events) {
          if (
            event.users.findIndex((eventUser: any) => {
              return (
                eventUser.id === user.id &&
                eventUser.checked_in_at !== null &&
                eventUser.accepted_at !== null
              );
            }) >= 0
          ) {
            points++;
          }
        }
        ranking.push({ user, points });
      }
      return orderBy(ranking, ["points"], ["desc"]).slice(0, 4);
    },
  },
  mounted() {
    this.$store.dispatch("getUsers");
    this.$store.dispatch("getEvents").catch((e) => {
      console.error(e);
      this.$router.push("/");
    });
    this.interval = setInterval(() => {
      this.$store.dispatch("getEvents");
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
});
