












































































































































































































































































import Vue from "vue";
import { default as dayjs } from "dayjs";
import { Modal } from "bootstrap";
import { QrcodeStream } from "vue-qrcode-reader";
export default Vue.extend({
  data() {
    return {
      dayjs: dayjs,
      eventUsersModal: null as any,
      checkInModal: null as any,
      qrCodeReader: {
        request: false,
        error: null,
      },
      loading: true,
      pin: "",
    };
  },
  components: {
    QrcodeStream,
  },
  props: ["event"],
  computed: {
    actionAble(): boolean {
      return !this.checkedIn && this.dayjs(this.event.starts_at).diff(this.dayjs(), "hour", true) > 48
    },
    checkInAble(): boolean {
      return (
        !this.checkedIn &&
        this.dayjs(this.event.starts_at).diff(this.dayjs(), "minute") < 59 &&
        this.dayjs(this.event.starts_at).diff(this.dayjs(), "minute") >
          -(60 * 2)
      ); // TN LISTE (+1) Partner von NAME
    },
    accepted(): boolean {
      return (
        this.event.users.findIndex(
          (user: any) =>
            user.id === this.$store.state.user.id && user.accepted_at !== null
        ) >= 0
      );
    },
    canceled(): boolean {
      return (
        this.event.users.findIndex(
          (user: any) =>
            user.id === this.$store.state.user.id && user.canceled_at !== null
        ) >= 0
      );
    },
    checkedIn(): boolean {
      return (
        this.event.users.findIndex(
          (user: any) =>
            user.id === this.$store.state.user.id && user.checked_in_at !== null
        ) >= 0
      );
    },
    participants(): any {
      return this.event.users.filter(
        (item: any) => item.accepted_at || item.checked_in_at
      );
    },
    participantCount(): number {
      return this.participants.reduce((a, { plus }) => a + plus + 1, 0);
    },
  },
  methods: {
    image(filename: string): string {
      return `${process.env.VUE_APP_API_BASE_URL}/images/${filename}`;
    },
    retry(): void {
      window.location.reload();
    },
    openCheckInModal(): void {
      this.checkInModal.show();
      this.qrCodeReader.request = true;
    },
    action(type: any): any {
      this.$store.dispatch("eventAction", { eventId: this.event.id, type });
    },
    async onInit(promise: any) {
      // show loading indicator
      this.loading = true;
      try {
        const { capabilities } = await promise;
        // successfully initialized
      } catch (error: any) {
        this.qrCodeReader.error = error.name;
        if (error.name === "NotAllowedError") {
          // user denied camera access permisson
        } else if (error.name === "NotFoundError") {
          // no suitable camera device installed
        } else if (error.name === "NotSupportedError") {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === "NotReadableError") {
          // maybe camera is already in use
        } else if (error.name === "OverconstrainedError") {
          // did you requested the front camera although there is none?
        } else if (error.name === "StreamApiNotSupportedError") {
          // browser seems to be lacking features
        }
      } finally {
        this.loading = false;
        // hide loading indicator
      }
    },
    onDecode(decoded: string): void {
      if (decoded === this.event.check_in_url) {
        this.action("check_in");
        this.checkInModal.hide();
      }
    },
    checkInWithPin() {
      console.log(this.event);
      if (this.pin === this.event.check_in_pin) {
        this.action("check_in");
        this.checkInModal.hide();
      }
    },
  },
  mounted(): void {
    this.eventUsersModal = new Modal(this.$refs.eventUsersModal as any);
    this.checkInModal = new Modal(this.$refs.checkInModal as any);
  },
});
